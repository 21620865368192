import React from 'react';
import "./adddashboard.css";
import { AddForm, DashboardNav } from '../../components';

const AddDashboard = () => {
    return (
        <div className='add-dashboard-container'>
            <div >
                <AddForm />
            </div>
        </div>
    )
}

export default AddDashboard

import React, { useState } from 'react';
import "./editdashboard.css";
import { Stack } from 'react-bootstrap';
import { EditDashboardNav, EditDashboardContainer } from '../../components';

const EditDashboard = () => {
    const [inventoryData, setInventoryData] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [subCategoryName, setSubCategoryName] = useState('');

    const handleNavItemSelect = (data, category, subCategory) => {
        setInventoryData(data);
        setCategoryName(category);
        setSubCategoryName(subCategory);
    };
    
    return (
        <div className='edit-dashboard-container'>
            <Stack direction='horizontal'>
                <EditDashboardNav className='d-flex justify-content-start' onNavItemSelect={handleNavItemSelect} />
                <EditDashboardContainer inventoryData={inventoryData} category={categoryName} subCategory={subCategoryName} />
            </Stack>
        </div>
    );
};

export default EditDashboard;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './editdashboardnav.css';
import Stack from 'react-bootstrap/esm/Stack';
import { Dropdown, DropdownButton, Button, Form } from 'react-bootstrap';

const EditDashboardNav = ({ onNavItemSelect }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [filterData, setFilterData] = useState({
    inventoryNumber: ''
  });

  const handleNavItemClick = (category, subCategory) => {
    setSelectedCategory(category);
    setSelectedSubCategory(subCategory);

    axios.get('https://www.oldhouseparts.com/getInventory.php', {
      params: {
        category,
        subCategory,
      },
    })
      .then(response => {
        console.log(response.data)
        onNavItemSelect(response.data, category, subCategory)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const handleArchiveClick = () => {
    setSelectedCategory('Archived');

    axios.get('https://www.oldhouseparts.com/getArchived.php')
      .then(response => {
        console.log(response.data)
        onNavItemSelect(response.data, "Archived")
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  };

  const handleAllClick = () => {

    axios.get('https://www.oldhouseparts.com/getAll.php')
      .then(response => {
        console.log(response.data)
        onNavItemSelect(response.data, "All")
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const handleFilterSearch = () => {
    console.log('Filter Data:', filterData);
    axios.get('https://www.oldhouseparts.com/getFiltered.php', {
      params: {
        filterData,
      },
    })
      .then(response => {
        console.log(response.data)
        onNavItemSelect(response.data, "Search", "")
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    handleAllClick();
  }, []);

  return (
        <Stack className='ps-3 edit-dashboard' gap={3}>

          <DropdownButton title="Search">
              <Form.Group className='m-3' controlId="formInventoryNumber">
                <Form.Label>ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter ID"
                  name="inventoryNumber"
                  value={filterData.inventoryNumber}
                  onChange={handleInputChange}
                />
              </Form.Group>
            <Button onClick={handleFilterSearch} variant='secondary' className='search-btn'>
              Search
            </Button>
          </DropdownButton>

          <Button onClick={handleAllClick} className='btn-alt' id="all">
            All Items
          </Button>

          <Button onClick={() => handleNavItemClick('Antiques', 'antique')} className='btn-alt' id="Antiques">
            Antiques
          </Button>

          <DropdownButton id="dropdown-windows" title="Windows">
            <Dropdown.Item onClick={() => handleNavItemClick('Windows', 'Stained and Leaded')}>
              <h6>Stained and Leaded</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Windows', 'Other')}>
              <h6>Other</h6>
            </Dropdown.Item>
          </DropdownButton>

          <DropdownButton id="dropdown-doors" title="Doors">
            <Dropdown.Item onClick={() => handleNavItemClick('Doors', 'Exterior')}>
              <h6>Exterior</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Doors', 'Interior')}>
              <h6>Interior</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Doors', 'Screen and Storm')}>
              <h6>Screen and Storm</h6>
            </Dropdown.Item>
          </DropdownButton>

          <DropdownButton id="dropdown-lighting" title="Lighting">
            <Dropdown.Item onClick={() => handleNavItemClick('Lighting', 'Flush and Semi-Flush')}>
              <h6>Flush and Semi-Flush</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Lighting', 'Sconces')}>
              <h6>Sconces</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Lighting', 'Shades and Globes')}>
              <h6>Shades and Globes</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Lighting', 'Hanging')}>
              <h6>Hanging</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Lighting', 'Other')}>
              <h6>Other</h6>
            </Dropdown.Item>
          </DropdownButton>

          <DropdownButton id="dropdown-plumbing" title="Plumbing">
            <Dropdown.Item onClick={() => handleNavItemClick('Plumbing', 'Bathtubs')}>
              <h6>Bathtubs</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Plumbing', 'Sinks')}>
              <h6>Sinks</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Plumbing', 'Fixtures')}>
              <h6>Fixtures and Globes</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Plumbing', 'Accessories')}>
              <h6>Accessories</h6>
            </Dropdown.Item>
          </DropdownButton>

          <DropdownButton id="dropdown-hardware" title="Hardware">
            <Dropdown.Item onClick={() => handleNavItemClick('Hardware', 'Doorknobs & Locksets')}>
              <h6>Doorknobs & Locksets</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Hardware', 'Doorbells & Knockers')}>
              <h6>Doorbells & Knockers</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Hardware', 'Escutcheons')}>
              <h6>Escutcheons</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Hardware', 'Pulls and Handles')}>
              <h6>Pulls and Handles</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Hardware', 'Other')}>
              <h6>Other</h6>
            </Dropdown.Item>
          </DropdownButton>

          <DropdownButton id="dropdown-other" title="Other">
            <Dropdown.Item onClick={() => handleNavItemClick('Other', 'Architectural Elements')}>
              <h6>Architectural Elements</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Other', 'Heat and Fireplace')}>
              <h6>Heat and Fireplace</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Other', 'Stone, Garden, and Masonry')}>
              <h6>Stone, Garden, and Masonry</h6>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleNavItemClick('Other', 'Newel Posts and Stair Parts')}>
              <h6>Newel Posts and Stair Parts</h6>
            </Dropdown.Item>
          </DropdownButton>

          <Button onClick={handleArchiveClick} className='btn-alt' id="archive">
            Archive
          </Button>
        </Stack>
  );
};

export default EditDashboardNav;

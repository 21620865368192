export const handleCategoryChange = (selectedCategoryValue, setSubCategories, setSelectedSubCategory) => {
    switch (selectedCategoryValue) {
        case 'antiques':
            setSubCategories(['Antique']);
            break;
        case 'doors':
            setSubCategories(['Exterior', 'Interior', 'Screen and Storm']);
            break;
        case 'windows':
            setSubCategories(['Stained and Leaded', 'Other']);
            break;
        case 'lighting':
            setSubCategories(['Flush and Semi-Flush', 'Hanging', 'Sconces', 'Shades and Globes', 'Other']);
            break;
        case 'plumbing':
            setSubCategories(['Bathtubs', 'Sinks', 'Accessories', 'Fixtures']);
            break;
        case 'hardware':
            setSubCategories(['Doorknobs & Locksets', 'Doorbells & Knockers', 'Escutcheons', 'Pulls and Handles', 'Other']);
            break;
        case 'other':
            setSubCategories(['Architectural Elements', 'Heat and Fireplace', 'Stone, Garden, and Masonry', 'Newel Posts and Stair Parts']);
            break;
        default:
            setSubCategories([]);
    }
    setSelectedSubCategory('');
};